exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-cercetare-[slug]-js": () => import("./../../../src/pages/cercetare/[slug].js" /* webpackChunkName: "component---src-pages-cercetare-[slug]-js" */),
  "component---src-pages-cercetare-index-js": () => import("./../../../src/pages/cercetare/index.js" /* webpackChunkName: "component---src-pages-cercetare-index-js" */),
  "component---src-pages-cercetare-page-[id]-js": () => import("./../../../src/pages/cercetare/page/[id].js" /* webpackChunkName: "component---src-pages-cercetare-page-[id]-js" */),
  "component---src-pages-confirma-cerere-oferta-js": () => import("./../../../src/pages/confirma-cerere-oferta.js" /* webpackChunkName: "component---src-pages-confirma-cerere-oferta-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-despre-noi-js": () => import("./../../../src/pages/despre-noi.js" /* webpackChunkName: "component---src-pages-despre-noi-js" */),
  "component---src-pages-devino-partener-js": () => import("./../../../src/pages/devino-partener.js" /* webpackChunkName: "component---src-pages-devino-partener-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-produs-js": () => import("./../../../src/pages/produs.js" /* webpackChunkName: "component---src-pages-produs-js" */),
  "component---src-pages-produse-[slug]-js": () => import("./../../../src/pages/produse/[slug].js" /* webpackChunkName: "component---src-pages-produse-[slug]-js" */),
  "component---src-pages-produse-index-js": () => import("./../../../src/pages/produse/index.js" /* webpackChunkName: "component---src-pages-produse-index-js" */),
  "component---src-pages-program-digitalizare-js": () => import("./../../../src/pages/program-digitalizare.js" /* webpackChunkName: "component---src-pages-program-digitalizare-js" */),
  "component---src-pages-solutii-[slug]-js": () => import("./../../../src/pages/solutii/[slug].js" /* webpackChunkName: "component---src-pages-solutii-[slug]-js" */),
  "component---src-pages-solutii-index-js": () => import("./../../../src/pages/solutii/index.js" /* webpackChunkName: "component---src-pages-solutii-index-js" */),
  "component---src-pages-termeni-si-conditii-js": () => import("./../../../src/pages/termeni-si-conditii.js" /* webpackChunkName: "component---src-pages-termeni-si-conditii-js" */),
  "component---src-pages-unsubscribe-js": () => import("./../../../src/pages/unsubscribe.js" /* webpackChunkName: "component---src-pages-unsubscribe-js" */),
  "component---src-pages-using-ssr-js": () => import("./../../../src/pages/using-ssr.js" /* webpackChunkName: "component---src-pages-using-ssr-js" */)
}

